<template>
    <main class="container main-content">
    <div>
        <!--<div class="notification display-flex flex-row justify-center">
            <p>Unfortunately the picks are not loading for the Four Nations Faceoff and I am on vacation so cannot fix/see what's wrong ☹️</p>
        </div>-->
        <div class="text-center" v-if="`${getErrorStatus}` === ''">
            <Spinner />
        </div>
        <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '520'">
            <span class="material-icons error-sign">error</span>
            <p class="error-message">The NHL stats API is currently down and Hockey Challenge Helper is unable to load pick stats.  Please check again when <a style="text-decoration:underline" href="https://www.nhl.com/stats/"> is working. Thanks for your understanding.</a></p>
        </div>
        <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '502'">
            <span class="material-icons error-sign">error</span>
            <p class="error-message">Error retrieving data from Tim Horton's, please try again later.</p>
        </div>
        <div class="site-status text-center" v-else-if="`${getErrorStatus}` === 'NoPicks'">
            <span class="material-icons error-sign">error</span>
            <p class="error-message">All the games have started! Check again tomorrow.</p>
        </div>
        <div class="site-status text-center" v-else-if="`${getErrorStatus}` === 'NoGames'  || `${getErrorStatus}` === 'TimsNoGames'">
            <span class="material-icons error-sign">error</span>
            <p class="error-message">There are no games today!  Check the NHL website for the schedule.</p>
        </div>
        <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '405'">
            <span class="material-icons error-sign">error</span>
            <p class="error-message">Stats are still being processed, please try again in a few seconds.</p>
        </div>
        <section class="homepage-stats" v-else-if="`${getErrorStatus}` === '200' || `${getErrorStatus}` === '400'">
            <FilterNavbar />
            <Spinner v-if="getSpinnerState" />
            <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '400'">
                <span class="material-icons error-sign">error</span>
                <p class="error-message">There are no stats available!</p>
            </div>
            <div class="content" v-else>
                <Warnings />
                <PlayerLists v-if="`${getDataScope}` === 'overall'" />
                <Trend v-else-if="`${getDataScope}` === 'trend'" />
                <PickLines v-else />
            </div>
        </section>
        <div class="site-status text-center" v-else>
            <span class="material-icons error-sign">error</span>
            <p class="error-message">Something went wrong!  Please try again later.</p>
        </div>
    </div>
    <Legend />
    </main>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Spinner from "@/components/Spinner";
import FilterNavbar from "@/components/FilterNavbar";
import Warnings from '@/components/Warnings';
import PlayerLists from "@/components/PlayerLists";
import Trend from "@/components/Trend";
import PickLines from "@/components/PickLines";
import Legend from "@/components/Legend";

export default {
  name: "Home",
  components: {
    Spinner,
    FilterNavbar,
    Warnings,
    PlayerLists,
    Trend,
    PickLines,
    Legend
    },
    meta: {
        title : "Tims Hockey Challenge Helper"
    },
  setup() {
    const store = useStore();

    // call getpicks function in actions in vuex
    store.dispatch('getPicks')
    // call getGameLogs function in actions in vuex
    store.dispatch('getGameLogs')

    const getErrorStatus = computed(() => {
      return store.state.error
    })

    const getSpinnerState = computed(() => {
      return store.state.spinner
    })

    const getDataScope = computed(() => {
      return store.state.dataScope
    })

    return { getSpinnerState, getDataScope, getErrorStatus }
  }
}
</script>
<style scoped>
.main-content {
  justify-content: space-between;
}
.disclaimer {
  text-align: center;
  font-size: 14px;
  margin: 10px 0;
}
.error-sign {
  font-size: 3rem;
}
.error-message {
  font-size: 1.2rem;
  font-weight: 600;
}
.site-status {
  margin-top: 2rem;
}
.notification {
  margin-top: 0.8rem;
}
.notification p {
  background-color: #fbecc0;
  border: 2px solid #dbbc64;
  padding: 6px 10px;
}
</style>